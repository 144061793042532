export default class Users {
	constructor(_app, url) {
		this._url = url;
	}

	url(path) {
		return this._url + path;
	}

	users() {
		return webix
			.ajax()
			.get(this.url("users/all"))
			.then(r => r.json());
	}
	avatarUrl(id, name) {
		return this.url(`${name.slice(1)}`);
	}
	share(id, user) {
		return webix
			.ajax()
			.post(this.url("share"), {
				id,
				user,
			})
			.then(r => r.json());
	}
	removeUser(id, user) {
		return webix
			.ajax()
			.del(this.url(`share?id=${encodeURIComponent(id)}&user=${user}`))
			.then(r => r.json());
	}
	comments(id) {
		return webix
			.ajax()
			.get(this.url(`comments?id=${encodeURIComponent(id)}`))
			.then(res => res.json());
	}
	addComment(id, value) {
		return webix
			.ajax()
			.post(this.url(`comments?id=${encodeURIComponent(id)}`), {
				value,
			})
			.then(res => res.json());
	}
	updateComment(id, value) {
		return webix
			.ajax()
			.put(this.url(`comments/${id}`), {
				value,
			})
			.then(res => res.json());
	}
	deleteComment(id) {
		return webix
			.ajax()
			.del(this.url(`comments/${id}`))
			.then(res => res.json());
	}
}
