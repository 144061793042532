export function getAvatar(name) {
	let result = "";
	let style = stylesheet[Math.floor(Math.random() * stylesheet.length)];
	if (typeof name !== "string") name = "";

	name = name.split(" ");

	for (let i = 0; i < name.length && result.length < 2; i++)
		if (name[i]) result += name[i].charAt(0);

	return `<div class="default_avatar" style="background:${style.bg}; color:${
		style.c
	};">${result || "no"}</div>`;
}

const stylesheet = [
	{ bg: "#2EADC2", c: "#FFFFFF" },
	{ bg: "#3B3ABB", c: "#FFFFFF" },
	{ bg: "#813EBB", c: "#FFFFFF" },
	{ bg: "#B442A8", c: "#FFFFFF" },
	{ bg: "#B43D64", c: "#FFFFFF" },
	{ bg: "#D25640", c: "#FFFFFF" },
	{ bg: "#DBA10D", c: "#FFFFFF" },
	{ bg: "#7BBD48", c: "#FFFFFF" },
	{ bg: "#5EBD90", c: "#FFFFFF" },
];
