import { services } from "@xbs/filemanager";

export default class Operations extends services.Operations {
	/**
	 * Adds event handlers to app actions
	 */
	initEvents() {
		super.initEvents();

		this.app.attachEvent("app:action", (name, info, target) => {
			switch (name) {
				case "trashed":
					this.addToClipboard(name);
					break;
				case "restore":
					this.restore(info, target);
					break;
				case "remove":
					this.removePermanent(info);
					break;
				case "favorite":
				case "unfavorite":
					this.favour(info);
					break;
			}
		});
	}

	/**
	 * Removes files and folders to Trash
	 * @param {Array} files - an array of folder/file data objects
	 */
	remove(files) {
		if (files || this.state.selectedItem.length)
			super.remove(files).then(() => {
				this.local().refresh("/", "trash");
			});
	}

	/**
	 * Restores files and folders from Trash to a target directory
	 * @param {Array} files - files and folders (Objects)
	 * @param {string} target - the ID of the target directory
	 * @returns {Promise}
	 */
	restore(files, target) {
		const state = this.state;

		const _ = this.app.getService("locale")._;
		if (!files) files = state.selectedItem;
		if (!files.length) return webix.promise.reject();

		files = this.extractIds(files);

		this.app.getService("progress").files(_("Restoring..."), files, f => {
			return this.backend()
				.restore(f, target)
				.then(res => {
					const local = this.local();
					local.deleteFile(f);
					local.addFile(this.dir(res.id), res);
				});
		});
	}

	/**
	 * Removes files and folders forever
	 * @param {Array} files - files and folders (Objects)
	 * @returns {Promise}
	 */
	removePermanent(files) {
		const state = this.state;
		const _ = this.app.getService("locale")._;

		if (files === "all") {
			files = this.local()
				.files("/", true, "trash")
				.serialize();
		} else {
			if (!files) files = state.selectedItem;
		}
		if (!files.length) return webix.promise.reject();

		files = this.extractIds(files);

		return webix
			.confirm({
				text: _("Are you sure ?"),
				container: this.app.getRoot().$view,
			})
			.then(() => {
				this.app.getService("progress").files(_("Deleting..."), files, f => {
					return this.backend()
						.removePermanent(f)
						.then(res => {
							if (!res.invalid) {
								this.local().deleteFile(f);
							}
						});
				});
			});
	}

	/**
	 * Adds selected files and folders to the clipboard (only for 'files' and 'trash' group folders)
	 * @param {string} mode - "cut", "copy", "trashed"
	 */
	addToClipboard(mode) {
		if (this.state.source === "files" || this.state.source === "trash")
			super.addToClipboard(mode);
	}

	/**
	 * Moves or copies files and folders from clipboard to the currently opened directory
	 * If the items in the clipboard are currently in Trash, they will be restored
	 * @param {Array<Object>} files - (optional) the array of currently selected files
	 */
	paste(files) {
		const state = this.state;
		if (!state.clipboard) return;

		if (state.source === "files") {
			if (state.clipboard.mode === "trashed")
				this.restore(state.clipboard.files, state.path);
			else super.paste(files);
		} else if (state.source === "trash" && state.clipboard.mode === "cut") {
			this.remove(state.clipboard.files);
		}
	}

	/**
	 * Adds or removes a file/folder to/from Favorite
	 * @param {Array} files - a file or a folder (Object)
	 */
	favour(files) {
		const file = files[0];
		const star = !file.star;
		const backend = this.backend();
		const method = star ? backend.favour : backend.unfavour;
		method.call(backend, file.id).then(res => {
			if (!res.invalid) {
				this.local().updateFile(res.id, { star });
				this.local().refresh("/", "favorite");
			}
		});
	}

	/**
	 * Returns the directory part of the path to the file/folder
	 * @param {string} path - full path
	 * @returns {string}
	 */
	dir(path) {
		return path.slice(0, path.lastIndexOf("/")) || "/";
	}

	/**
	 * Opens text of Excel editor for the given files
	 * (decides which editor by the type of the first file and filters out the irrelevant ones)
	 * @param {Array} files - files (Objects)
	 */
	edit(files) {
		const state = this.state;
		if (!files) files = state.selectedItem;
		files = files.filter(
			file =>
				file.type === "code" || file.$ext === "xls" || file.$ext === "xlsx"
		);

		if (files.length) {
			const textEditor = files[0].type === "code";

			if (textEditor) {
				files = files.filter(file => file.type === "code");
			} else {
				files = files.filter(
					file => file.$ext === "xls" || file.$ext === "xlsx"
				);
				files = [files[files.length - 1]];
			}

			this.app.show(textEditor ? "/editor" : "/editor.excel", {
				params: {
					files,
					state,
					compact: this.app.getSubView().Compact,
				},
			});
		}
	}

	/**
	 * Saves new content to an Excel file
	 * @param {string} id - the ID of a file
	 * @param {string} filename - the name of the file
	 * @param {Blob} file
	 * @returns {Promise}
	 */
	writeBinary(id, filename, file) {
		return this.backend()
			.writeBinary(id, filename, file)
			.then(data => {
				return this.local().updateFile(id, {
					size: data.size,
					date: data.date,
				});
			});
	}

	/**
	 * Returns the Users service
	 * @return {Object}
	 */
	users() {
		return this.app.getService("users");
	}

	/**
	 * Adds a user to the list of users who can access a file
	 * @param {Object} file - the file data object
	 * @param {number} uid - the user ID
	 * @returns {Promise}
	 */
	share(file, uid) {
		return this.users()
			.share(file.id, uid)
			.then(res => {
				return this.local().updateFile(res.id, {
					users: (file.users || []).concat(uid),
				});
			});
	}

	/**
	 * Removes a user from the list of users who can access a file
	 * @param {Object} file - the file data object
	 * @param {number} uid - the user ID
	 * @returns {Promise}
	 */
	removeUser(file, uid) {
		return this.users()
			.removeUser(file.id, uid)
			.then(res => {
				return this.local().updateFile(res.id, {
					users: file.users.filter(u => u !== uid),
				});
			});
	}

	/**
	 * Returns the Tags service
	 * @returns {Object}
	 */
	tags() {
		return this.app.getService("tags");
	}

	/**
	 * Returns the LocalTags service
	 */
	localtags() {
		return this.app.getService("localtags");
	}

	/**
	 * Adds a new tag to DM database, optionally attaches it to a file/folder
	 * @param {Object} data - data of new tag
	 * @param {string} tags - (optional) the list of current tags of a file/folder (can also be "")
	 * @returns {Promise}
	 */
	addTag(data, tags) {
		return this.tags()
			.addTag(data)
			.then(res => {
				if (tags !== undefined) {
					const files = this.state.selectedItem;
					const file = files[files.length - 1];

					tags = tags ? tags + "," + res.id : res.id;

					this.tags()
						.setTags(file.id, tags)
						.then(() => {
							this.app.callEvent("current:tags:updated");
						});
				}

				return this.localtags().addTag(res);
			});
	}

	/**
	 * Updates a tag (new name, new color)
	 * @param {number} id - the ID of a tag
	 * @param {Object} data - new data for a tag
	 * @returns {Promise}
	 */
	updateTag(id, data) {
		return this.tags()
			.updateTag(id, data)
			.then(res => {
				this.app.callEvent("current:tags:updated");
				this.localtags().updateTag(id, res);
			});
	}

	/**
	 * Removes a tag (from DM and - on the backend - from related files/folders)
	 * @param {number} id - the ID of a tag
	 */
	removeTag(id) {
		const _ = this.app.getService("locale")._;
		webix
			.confirm({
				text: _("Are you sure ?"),
				container: this.app.getRoot().$view,
			})
			.then(() => {
				this.tags()
					.removeTag(id)
					.then(res => {
						this.app.callEvent("current:tags:updated");
						this.localtags().removeTag(res.id);
					});
			});
	}
}
