import { views } from "@xbs/filemanager";

export default class CommentsView extends views.JetView {
	config() {
		const backend = this.app.getService("users");
		return this.app
			.getService("localusers")
			.users()
			.then(users => {
				return {
					localId: "comments",
					view: "comments",
					css: "webix_fmanager_comments",
					mentions: true,
					users: users,
					currentUser: 1,
					scheme: {
						$init: this.PrepareComments,
					},
					listItem: {
						templateAvatar: function(obj) {
							var user = users.getItem(obj.user_id);
							return `<div class="webix_comments_avatar"><img class="webix_comments_avatar_image" src="${backend.avatarUrl(
								user.id,
								user.avatar
							)}" /></div>`;
						},
					},
				};
			});
	}
	init() {
		this.Comments = this.$$("comments");
		this.on(this.Comments, "onBeforeAdd", (_id, obj) => this.AddComment(obj));
		this.on(
			this.Comments.queryView("list").data,
			"onDataUpdate",
			(id, obj, old) => this.UpdateComment(id, obj, old)
		);
		this.on(this.Comments, "onBeforeDelete", id => this.RemoveComment(id));

		webix.extend(this.Comments.queryView("list"), webix.OverlayBox);
	}
	urlChange() {
		const file = this.getParam("file");
		this.LoadComments(file);
	}
	LoadComments(v) {
		this.File = v;
		this.app
			.getService("users")
			.comments(v.id)
			.then(data => {
				this.Comments.clearAll();
				this.Comments.parse(data);
				this.ChangeListState();
			});
	}
	ChangeListState() {
		if (this.Comments.count()) {
			this.Comments.queryView("list").hideOverlay();
		} else {
			const _ = this.app.getService("locale")._;
			this.Comments.queryView("list").showOverlay(
				`<div class="webix_fmanager_overlay">${_("No comments yet")}</div>`
			);
		}
	}
	PrepareComments(o) {
		if (o && typeof o.date === "string") o.date = new Date(o.date);
	}
	AddComment(obj) {
		this.app
			.getService("users")
			.addComment(this.File.id, obj.text)
			.then(res => {
				obj.id = res.id * 1;
				this.Silently("add", [obj]);
				this.ChangeListState();
			});
		return false;
	}
	UpdateComment(id, obj, old) {
		this.app
			.getService("users")
			.updateComment(id, obj.text)
			.catch(() => {
				this.Comments.getItem(id).text = old.text;
				this.Comments.refresh(id);
			});
	}
	RemoveComment(id) {
		this.app
			.getService("users")
			.deleteComment(id)
			.then(res => {
				this.Silently("remove", [res.id]);
				this.ChangeListState();
			});
		return false;
	}
	Silently(operation, params) {
		const comments = this.Comments;
		comments.blockEvent();
		comments[operation](...params);
		comments.unblockEvent();
	}
}
