import { views } from "@xbs/filemanager";

export default class TopView extends views.top {
	init() {
		super.init();

		this.on(this.app, "app:action", (name, info) => {
			if (name === "share") {
				this.show("./menus.popup/menus.sharepopup", {
					target: "popup",
					params: { info, compact: this.Compact, label: "Share File" },
				});
			} else if (name === "tags") {
				this.show("./menus.popup/menus.tagspopup", {
					target: "popup",
					params: { compact: this.Compact, label: "Manage Tags" },
				});
			}
		});
	}
}
