export default class Tags {
	constructor(_app, url) {
		this._url = url;
	}

	url(path) {
		return this._url + path;
	}
	tags() {
		return webix
			.ajax()
			.get(this.url("tags/all"))
			.then(r => r.json());
	}
	getTags(id) {
		return webix
			.ajax()
			.get(this.url(`tags?id=${encodeURIComponent(id)}`))
			.then(r => r.json());
	}
	setTags(id, value) {
		return webix
			.ajax()
			.put(this.url("tags"), {
				id,
				value,
			})
			.then(r => r.json());
	}
	addTag(data) {
		return webix
			.ajax()
			.post(this.url("tags"), data)
			.then(res => res.json());
	}
	updateTag(id, data) {
		return webix
			.ajax()
			.put(this.url(`tags/${id}`), data)
			.then(res => res.json());
	}
	removeTag(id) {
		return webix
			.ajax()
			.del(this.url(`tags/${id}`))
			.then(res => res.json());
	}
}
