export default class LocalTags {
	constructor(app) {
		this.app = app;

		this.taglist = new webix.DataCollection({});
		this.tags_ready = null;
	}
	tags(force) {
		const tags = this.taglist;

		if (force || !this.tags_ready) {
			this.tags_ready = this.app
				.getService("tags")
				.tags()
				.then(data => {
					tags.clearAll();
					tags.parse(data);

					return tags;
				});
		}

		return this.tags_ready;
	}
	addTag(data) {
		return this.taglist.add(data);
	}
	updateTag(id, data) {
		if (this.taglist.exists(id)) this.taglist.updateItem(id, data);
	}
	removeTag(id) {
		if (this.taglist.exists(id)) this.taglist.remove(id);
	}
}
