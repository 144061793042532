import { views } from "@xbs/filemanager";

export default class PanelView extends views.panel {
	config() {
		const ui = super.config();

		const refreshIcon = ui.rows[0].cols[0];
		refreshIcon.click = () => {
			const source = this.State.source !== "files" ? this.State.source : "";
			this.app.getService("local").refresh(this.State.path, source);
		};

		return ui;
	}
	init() {
		super.init();

		const uapi = this.app.getService("upload").getUploader();
		this.on(uapi, "onBeforeFileDrop", (_files, e) => {
			if (
				this.getRoot().$view.contains(e.target) &&
				this.State.source !== "files"
			)
				return false;
		});

		this.on(this.State.$changes, "source", v => this.ProcessSource(v));
	}

	/**
	 * Handles path change and sets correct text for the bread crumb widget
	 * @param {string} path
	 */
	ProcessPath(path) {
		if (this.State.source === "files") super.ProcessPath(path);
	}

	/**
	 * Handles source change and sets correct text for the bread crumb widget
	 * @param {string} source - source ID
	 */
	ProcessSource(source) {
		const _ = this.app.getService("locale")._;
		this.app
			.getService("local")
			.folders()
			.then(dirs => {
				if (source !== "files" || this.State.path === "/") {
					const sourceName = _(dirs.getItem("../" + source).value);
					this.$$("path").setValues({ source: sourceName });
				}
			});
	}

	/**
	 * Defines a template for the bread-crumbs widget
	 * @param {Object} obj - an object with either path or source property with the text to be shown
	 * @returns {string} HTML template
	 */
	RenderPath(obj) {
		if (obj.path) return super.RenderPath(obj);
		if (obj.source) return this.RenderSource(obj);
		return "";
	}

	/**
	 * Defines a template for the bread-crumbs widget
	 * @param {Object} obj - an object with the source property with the text to be shown
	 * @returns {string} HTML template
	 */
	RenderSource(obj) {
		return `<span class="webix_fmanager_path_chunk" data-path="0">${obj.source}</span>`;
	}
}
