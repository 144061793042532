import { views } from "@xbs/filemanager";

import { getAvatar } from "helpers/avatar";
import { deleteIcon } from "helpers/icons";

export default class SharePopupView extends views.JetView {
	config() {
		const _ = this.app.getService("locale")._;
		const compact = this.getParam("compact", true);

		const input = {
			localId: "users",
			view: "text",
			placeholder: _("Invite someone..."),
			suggest: {
				template: "#email#",
				body: {
					css: "webix_fmanager_user_list",
					data: [],
					template: obj => this.InputListTemplate(obj),
				},
			},
		};

		const label = {
			localId: "counter",
			borderless: true,
			template: obj => {
				if (!obj) return "&nbsp;";
				return obj.users
					? _("Available to") + " " + obj.users + " " + _("user(s)")
					: _("No users");
			},
			autoheight: true,
		};

		const list = {
			view: "list",
			borderless: true,
			localId: "list",
			css: "webix_fmanager_shared_list",
			template: obj => this.ListTemplate(obj),
			type: { height: webix.skin.$active.rowHeight },
			autoheight: !compact,
			yCount: compact ? 0 : 4,
			onClick: {
				webix_fmanager_remove_user: (_e, id) => this.RemoveUser(id * 1),
			},
		};

		const body = {
			borderless: true,
			padding: 12,
			margin: 4,
			rows: [
				input,
				{
					view: "button",
					label: _("Invite"),
					inputWidth: 140,
					css: "webix_primary",
					click: () => this.Done(),
				},
				label,
				list,
			],
		};

		if (compact) body.rows.push({ gravity: 0.00001 });

		return body;
	}
	init() {
		this.List = this.$$("list");
		this.Input = this.$$("users");
		this.File = this.getParam("info", true)[0];

		this.app
			.getService("localusers")
			.users()
			.then(data => {
				this.Data = data;
				const list = webix.$$(this.Input.config.suggest).getList();
				list.clearAll();
				list.sync(data);

				let users = this.File.users;
				if (users && users.length) {
					this.List.parse(users.map(u => data.getItem(u)));
				}
				this.UpdateCounter(users);
			});

		this.Input.focus();
		webix.TooltipControl.addTooltip(this.List.$view);
	}
	RemoveUser(id) {
		const _ = this.app.getService("locale")._;
		webix
			.confirm({
				text: _("Are you sure ?"),
				container: this.app.getRoot().$view,
			})
			.then(() =>
				this.app
					.getService("operations")
					.removeUser(this.File, id)
					.then(() => {
						this.List.remove(id);
						this.UpdateCounter(this.File.users);
					})
			);
	}
	ClearInput() {
		this.Input.setValue("");
	}

	Done() {
		let email = this.Input.getValue();
		if (email) {
			const user = this.Data.find(a => a.email === email, true);

			if (user && !this.List.find(a => a.id === user.id, true)) {
				return this.app
					.getService("operations")
					.share(this.File, user.id)
					.then(() => {
						this.List.add(user);
						this.List.showItem(user.id);
						this.ClearInput();
						this.UpdateCounter(this.File.users);
					});
			} else this.ClearInput();
		}
	}
	ListTemplate(obj) {
		const avatar = obj.avatar
			? `<img class="avatar_img" webix_tooltip="${
					obj.email
			  }" src="${this.app.getService("users").avatarUrl(obj.id, obj.avatar)}">`
			: getAvatar(obj.name);

		return (
			avatar +
			`<span webix_tooltip="${obj.email}">${obj.name}</span>` +
			deleteIcon
		);
	}
	InputListTemplate(obj) {
		return (
			obj.name +
			"&nbsp;" +
			`<span class="webix_fmanager_user_email">${obj.email}</span>`
		);
	}
	UpdateCounter(users) {
		this.$$("counter").setValues({ users: users ? users.length : 0 });
		if (users && users.length) {
			webix.html.removeCss(this.List.$view, "no-border");
		} else {
			webix.html.addCss(this.List.$view, "no-border");
		}
	}
}
