import { views } from "@xbs/filemanager";
import { menuIcon, folderCardIcon } from "../helpers/icons";
import DataviewMixin from "helpers/dataview";

export default class CardsView extends views["cards"] {
	config() {
		DataviewMixin(this);
		return this.app
			.getService("localusers")
			.users()
			.then(users => {
				this.Users = users;

				const ui = super.config();
				ui.on.onBeforeDrag = ctx => this.HandleDrag(ctx);
				ui.on.onBeforeDrop = ctx => this.HandleDrop(ctx);

				return ui;
			});
	}
	init() {
		super.init();

		this.SourceHandlers();
		this.DragMark();
		webix.TooltipControl.addTooltip(this.WTable.$view);
	}

	/**
	 * Parses data into Cards
	 * @param {Object} data - DataCollection with files and folders
	 */
	RenderData(data) {
		this.WTable.sync(data, () =>
			this.WTable.filter(f => f.value && f.value !== "..")
		);
	}

	/**
	 * Returns the HTML of a card
	 * @param {Object} obj - the data object of a file or a folder
	 * @returns {string} with HTML of a card
	 */
	CardTemplate(obj) {
		let shared = "";
		if (obj.users && obj.users.length) {
			shared = "<div class='webix_fmanager_card_shared'>";
			const circles = [];
			const offst = obj.users.length > 4 ? 1 : 0;

			for (let i = 0; i < obj.users.length; ++i) {
				if (i < 3 || (i === 3 && i - obj.users.length === -1)) {
					const user = this.Users.getItem(obj.users[i]);
					circles.push(
						`<img class="shared_user_name user_${offst + i}" webix_tooltip="${
							user.name
						}<br>(${user.email})" src="${this.app
							.getService("users")
							.avatarUrl(user.id, user.avatar)}" />`
					);
				} else break;
			}
			if (offst)
				circles.unshift(
					`<span class="shared_user_name user_plus">+${obj.users.length -
						3}</span>`
				);
			shared += circles.reverse().join("");
			shared += "</div>";
		}

		const _ = this.app.getService("locale")._;

		const star =
			obj.star && this.State.source !== "trash"
				? "<div class='webix_fmanager_star'><span class='webix_docmanager_icon dmi-star'></span></div>"
				: "";

		let preview, panel;
		if (obj.type === "folder") {
			preview = `<div class="webix_fmanager_card_preview">${star}${folderCardIcon}</div>`;

			panel = `<div class="webix_fmanager_card_panel">
		<span class="webix_fmanager_card_label">${_("Folder")}</span>
		<span class="webix_fmanager_card_name folder">${this.SearchTemplate(
			obj.value
		)}</span>${menuIcon}
		</div>`;
		} else {
			const skin = webix.skin.$active;
			const picSize = skin.listItemHeight < 29 ? [163, 92] : [214, 124];

			const origin = this.app
				.getService("backend")
				.previewURL(obj, picSize[0], picSize[1]);
			const img = `<img height="${picSize[1]}" width="${
				picSize[0]
			}" src="${origin}" onerror='this.style.display="none"'/>`;

			preview = `<div class="webix_fmanager_card_preview file">${star}${img}${shared}</div>`;

			const fileIcon = this.Icon(obj);

			panel = `<div class="webix_fmanager_card_panel file">
		<span class="webix_fmanager_card_name">${fileIcon}<span class="file_name_text">${this.SearchTemplate(
				obj.value
			)}</span></span>${menuIcon}
		</div>`;
		}

		return `<div class="webix_fmanager_card_outer"><div class="webix_fmanager_card" webix_l_id="${
			obj.id
		}">${preview + panel}</div></div>`;
	}

	/**
	 * Highlights the part of a filename that matches the current search query
	 * @param {string} name - the name of the file or a folder
	 * @returns {string} with HTML of a matching substring
	 */
	SearchTemplate(name) {
		let search = this.State.search;

		// remove tags
		search = search.replace(/(#\w+\b)/g, "").trim();

		if (search) {
			// highlight all words
			const rex = new RegExp("(" + search.replace(/ +/g, ")|(") + ")", "gi");

			return name.replace(
				rex,
				"<span class='webix_fmanager_search_mark'>$&</span>"
			);
		}

		return name;
	}
}
