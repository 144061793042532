import { views } from "@xbs/filemanager";
import BackFromHistory from "./back";

export default class HistoryView extends views.JetView {
	config() {
		this.File = this.getParam("file");
		this.State = this.getParam("state");
		const compact = this.getParam("compact");
		const _ = this.app.getService("locale")._;

		return this.app
			.getService("localusers")
			.users()
			.then(users => {
				const toolbar = {
					view: "toolbar",
					elements: [
						{
							view: "label",
							label: _("Version history"),
							align: "center",
							css: "webix_fmanager_history_bar",
						},
					],
				};

				if (!compact) {
					toolbar.elements.unshift(BackFromHistory);
				}

				const check = {
					view: "checkbox",
					localId: "diffCheck",
					labelWidth: 12,
					labelRight: _("Show changes"),
					value: this.State.diff,
					on: {
						onChange: v => (this.State.diff = !!v),
					},
				};

				const list = {
					type: "form",
					margin: 0,
					padding: 0,
					rows: [
						toolbar,
						{
							view: "list",
							localId: "list",
							width: compact ? 0 : 300,
							select: true,
							borderless: true,
							scheme: {
								$init: this.PrepareDates,
							},
							type: {
								height: "auto",
							},
							template: obj => this.ListTemplate(obj, users),
							on: {
								onAfterSelect: id => this.SetVersion(id),
							},
						},
						check,
						{ height: 12 },
					],
				};

				const diff = {
					localId: "diff",
					scroll: "auto",
					hidden: this.File.type !== "code",
					view: "template",
					css: "webix_docmanager_editor_diff",
				};

				const ui = compact
					? { type: "wide", rows: [diff, list] }
					: { type: "wide", cols: [diff, list] };

				return ui;
			});
	}
	init() {
		this.List = this.$$("list");
		this.Diff = this.$$("diffCheck");

		this.dateFormat = webix.Date.dateToStr("%F %j, %Y %H:%i:%s");

		this.on(this.State.$changes, "version", v =>
			this.GetVersion(v, this.State.diff)
		);
		this.on(this.State.$changes, "diff", v =>
			this.GetVersion(this.List.getSelectedId(), v)
		);

		this.on(this.app, "history:close", () => this.$$("diff").setHTML(""));

		this.LoadData();
	}

	/**
	 * Loads the version history of a file and selects the latest version
	 */
	LoadData() {
		this.app
			.getService("versions")
			.versions(this.File.id)
			.then(res => {
				this.List.parse(res);
				this.List.select(this.List.getFirstId());
			});
	}

	/**
	 * Converts string dates to Date objects
	 * @param {Object} o - the data object of a version
	 */
	PrepareDates(o) {
		if (typeof o.date === "string") o.date = new Date(o.date);
		if (typeof o.origin === "string") o.origin = new Date(o.origin);
	}

	/**
	 * Defines how a version is displayed in the list
	 * @param {Object} obj - the data object of a version
	 * @param {Object} users - DataCollection with all users
	 * @returns {string} with HTML of a list item
	 */
	ListTemplate(obj, users) {
		const _ = this.app.getService("locale")._;
		let dateline = `<div class="webix_fmanager_version_date">${this.dateFormat(
			obj.date
		)}</div>`;
		if (obj.origin) {
			dateline += `<div class="webix_fmanager_restore_date">${_(
				"Restored from"
			)} ${this.dateFormat(obj.origin)}</div>`;
		}

		const user = users.getItem(obj.user);
		const face = `<img class="webix_fmanager_version_face" webix_tooltip="${
			user.name
		}<br>(${user.email})" src="${this.app
			.getService("users")
			.avatarUrl(user.id, user.avatar)}" />`;
		const authorline = `<div class="webix_fmanager_version_author">${face}<span class="webix_fmanager_version_name">${user.name}</span></div>`;

		return `<div class="webix_fmanager_version">${dateline}${authorline}</div>`;
	}

	/**
	 * Selects a version of a file
	 * @param {number} id - the ID of a version
	 */
	SetVersion(id) {
		const version = this.List.getItem(id);

		this.State.$batch({
			version: id,
			current: webix.Date.equal(this.File.date, version.date),
			date: version.date,
		});
	}

	/**
	 * Gets the text of the particular version of a file
	 * @param {number} v - the ID of a version
	 * @param {Boolean} diff - true if you want to compare this version to the previous, false if not
	 */
	GetVersion(v, diff) {
		if (v && this.File.type === "code") {
			this.app
				.getService("versions")
				.readText(v, diff)
				.then(text => {
					if (v == this.List.getLastId() && diff) {
						text = `<span class="webix_docmanager_diff_insert">${text}</span>`;
					}
					this.$$("diff").setHTML(text);
				});
		}
	}

	/**
	 * Gets the data object of the previous version
	 * @param {number} curr - the ID of a version
	 * @returns {Object} - the data object of a version
	 */
	GetPrevVersion(curr) {
		return this.List.getNextId(curr);
	}
}
