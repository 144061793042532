export function randomColor() {
	return palette[Math.floor(Math.random() * 4)][Math.floor(Math.random() * 4)];
}

export const palette = [
	["#00a037", "#13a1aa", "#df282f", "#fd772c"],
	["#6d4bce", "#b26bd3", "#c87095", "#90564d"],
	["#eb2f89", "#ea77c0", "#777676", "#a9a8a8"],
	["#9bb402", "#e7a90b", "#0bbed7", "#038cd9"],
];
