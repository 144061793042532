import { views } from "@xbs/filemanager";
import { randomColor } from "../../helpers/common";

export default class InfoPreview extends views["preview/info"] {
	config() {
		const _ = this.app.getService("locale")._;

		const tags = {
			css: "webix_fmanager_tags_line",
			rows: [
				{
					view: "label",
					css: "webix_fmanager_info_title",
					label: _("Tags"),
				},
				{
					cols: [
						{
							view: "multicombo",
							localId: "tags:combo",
							css: "preview_tags",
							placeholder: _("Add a tag"),
							newValues: true,
							options: {
								template: "#name#",
								body: {
									data: [],
									template: obj => this.TaglistTemplate(obj),
								},
							},
							on: {
								onItemClick: (_id, e) => this.SearchTag(e.target),
							},
						},
						{
							rows: [
								{
									cols: [
										{
											view: "icon",
											icon: "wxi-plus",
											css: "webix_fmanager_tags_plus",
											tooltip: _("Add a tag"),
											click: () => this.OpenTagList(),
										},
										{
											view: "icon",
											icon: "dmi-cog",
											css: "webix_fmanager_tag_settings",
											tooltip: _("Open tag settings"),
											click: () => {
												this.app.callEvent("app:action", ["tags"]);
											},
										},
									],
								},
							],
						},
					],
				},
			],
		};

		const ui = super.config();
		const info = ui.cells[0].body;
		info.css = "webix_fmanager_info";
		info.rows.splice(0, 0, tags);

		return ui;
	}

	init() {
		super.init();

		this.Tags = this.$$("tags:combo");
		this.HandleTagUpdate();
		this.HandleColoring();
		this.HandleTagCreation();

		this.on(this.app, "current:tags:updated", () => this.ReloadTags());
	}

	/**
	 * Opens the dropdown list with all tags
	 */
	OpenTagList() {
		this.Tags.getInputNode().click();
		this.Tags.getList().show(this.Tags.$view);
	}

	/**
	 * Shows info on the selected file/folder (here also shows tags)
	 * @param {string} v - the ID of a file or a folder
	 */
	ShowInfo(v) {
		super.ShowInfo(v);

		if (v.type !== "empty") {
			this.File = v;
			const all = this.app.getService("localtags").tags();
			const ftags = this.app.getService("tags").getTags(v.id);
			webix.promise.all([all, ftags]).then(result => {
				this.Tags.getList().parse(result[0]);
				this.SetTagsSilent(result[1]);
			});
		}
	}

	/**
	 * Handles tag selection and updates tags of a file/folder
	 */
	HandleTagUpdate() {
		this.on(this.Tags, "onChange", (nt, ot) => {
			if (this.File) {
				this.app
					.getService("tags")
					.setTags(this.File.id, nt.join())
					.catch(() => {
						this.SetTagsSilent(this.Tags, ot);
					});
			}
		});
	}

	/**
	 * Updates the value of the combo without calling related Webix events
	 * @param {string, Array} value - the list of tags as a string (comma-separated) or an array
	 */
	SetTagsSilent(value) {
		this.Tags.blockEvent();
		this.Tags.setValue(value);
		this.Tags.unblockEvent();
	}

	/**
	 * Defines the way tags look in the combo input
	 */
	HandleColoring() {
		const smallSkin =
			webix.skin.$name === "mini" || webix.skin.$name === "compact";

		this.Tags.$renderTag = function(text, width, height, value) {
			const data = this.getList().getItem(value);
			const content = `<span class="webix_multicombo_text">${text}</span><span class="webix_multicombo_delete" role="button" aria-label="${webix.i18n.aria.removeItem}"></span>`;
			return `<li class="webix_multicombo_value" style="line-height: ${height +
				(smallSkin ? 2 : 0)}px; max-width:${width}px; background-color: ${
				data.color
			};" optvalue="${webix.template.escape(value)}">${content}</li>`;
		};
	}

	/**
	 * Reloads tags of a file/folder
	 */
	ReloadTags() {
		this.app
			.getService("tags")
			.getTags(this.File.id)
			.then(res => {
				this.SetTagsSilent(res);
			});
	}

	/**
	 * Initiates search by a tag
	 * @param {HTMLElement} target - the element of a tag that received a click
	 */
	SearchTag(target) {
		let node;
		if (target.className === "webix_multicombo_value") node = target;
		else if (target.parentNode.className === "webix_multicombo_value")
			node = target.parentNode;
		if (!node) return;

		this.Tags.getPopup().hide();
		const value = node.getAttribute("optvalue");
		this.getParam("state").search =
			"#" + this.Tags.getList().getItem(value).value;
	}

	/**
	 * Switches the lower tabbar from Info to Search and vice versa
	 * @param {Object} tab - tabbar with Information and Search results
	 * @param {string} hide - the ID of a cell to hide
	 * @param {string} show - the ID of a cell to show
	 */
	SwitchTabs(tab, hide, show) {
		this.State.search && !this.State.selectedItem.length
			? tab.show()
			: tab.hide();
		super.SwitchTabs(tab, hide, show);
	}

	/**
	 * Handles new input into compo input field and adds a new tag to the file/folder and into DM database
	 */
	HandleTagCreation() {
		const popupList = this.Tags.getList();
		this.on(popupList, "onBeforeAdd", (id, data) => {
			this.AddTag({ name: data.value, tags: this.Tags.getValue() });
			return false;
		});

		this.SetTaglistOverlay(popupList);
	}

	/**
	 * Adds a new tag into DM database and to the selected file/folder
	 * @param {Object} data - a data object of the new tag
	 */
	AddTag(data) {
		const tag = { name: data.name, color: randomColor() };
		this.app
			.getService("operations")
			.addTag(tag, data.tags)
			.then(() => {
				this.ReloadTags();
			});
	}

	/**
	 * Sets event handlers for the dropdown list of the tag combo that toggle the overlay
	 * @param {Object} list - the list inside the dropdown of the combo
	 */
	SetTaglistOverlay(list) {
		webix.extend(list, webix.OverlayBox);

		this.on(list.data, "onAfterFilter", () => this.ToggleOverlay(list));
		this.on(list.getTopParentView(), "onShow", () => this.ToggleOverlay(list));
	}

	/**
	 * Toggles the overlay on the dropdown list of the tag combo
	 * @param {Object} list - the list inside the dropdown of the combo
	 */
	ToggleOverlay(list) {
		const _ = this.app.getService("locale")._;
		if (!list.count()) {
			list.define({
				yCount: 1,
				autoheight: false,
			});

			list.showOverlay(_("Press Enter to create a new tag"));
		} else {
			list.define({ autoheight: true, yCount: 5 });
			list.hideOverlay();
		}
		list.resize();
	}

	/**
	 * Defines the way tags are shown in the dropdown list of the combo
	 * @param {Object} obj - tag data object
	 * @returns {string} with HTML
	 */
	TaglistTemplate(obj) {
		const lih = webix.skin.$active.listItemHeight;
		const color = `<span class="webix_fmanager_tag_marker" style="background-color:${
			obj.color
		}; height:${lih - 12}px; width:${lih - 12}px; margin-top:${lih /
			10}px;">&nbsp;</span>`;
		return obj.name + color;
	}
}
