import * as fm from "@xbs/filemanager";
import { link } from "jet-restate";
import { initJetWin } from "@xbs/jet-helpers";

import views from "./export_views";
import en from "locales/en";

import Backend from "./models/Backend";
import LocalData from "./models/LocalData";
import Operations from "./models/Operations";
import Users from "./models/Users";
import LocalUsers from "./models/LocalUsers";
import Tags from "./models/Tags";
import LocalTags from "./models/LocalTags";
import Versions from "./models/Versions";

export class App extends fm.App {
	constructor(config) {
		const defaults = {
			version: VERSION,
		};

		const override = new Map([
			[fm.views["top"], views["top"]],
			[fm.views["cards"], views["cards"]],
			[fm.views["editor"], views["editor"]],
			[fm.views["folders"], views["folders"]],
			[fm.views["list"], views["list"]],
			[fm.views["menus/menubody"], views["menus/menubody"]],
			[fm.views["menus/contextmenu"], views["menus/contextmenu"]],
			[fm.views["panel"], views["panel"]],
			[fm.views["panel-double"], views["panel-double"]],
			[fm.views["preview/info"], views["preview/info"]],
			[fm.views["preview"], views["preview"]],
			[fm.views["topbar"], views["topbar"]],
			[fm.services.Backend, services.Backend],
			[fm.services.LocalData, services.LocalData],
			[fm.services.Operations, services.Operations],
		]);

		if (config.override) config.override.forEach((v, k) => override.set(k, v));

		super({ ...defaults, ...config, override });

		this.setService("users", new (this.dynamic(Users))(this, this.config.url));
		this.setService("localusers", new (this.dynamic(LocalUsers))(this));
		this.setService("tags", new (this.dynamic(Tags))(this, this.config.url));
		this.setService("localtags", new (this.dynamic(LocalTags))(this));
		this.setService(
			"versions",
			new (this.dynamic(Versions))(this, this.config.url)
		);
		initJetWin(this);
	}
	require(type, name) {
		if (type === "jet-views") return views[name];
		else if (type === "jet-locales") return locales[name];

		return null;
	}
	dynamic(obj) {
		const override = this.config.override;
		if (override) {
			let s;
			while (obj) {
				s = obj;
				obj = override.get(obj);
			}
			return s;
		}
		return obj;
	}
}

webix.protoUI(
	{
		name: "docmanager",
		app: App,
		getState() {
			return this.$app.getState();
		},
		getService(name) {
			return this.$app.getService(name);
		},
		$init() {
			const state = this.$app.getState();
			for (let key in state) {
				link(state, this.config, key);
			}
		},
	},
	webix.ui.jetapp
);

// re-export for customization
webix.extend(views, fm.views);
const services = {
	LocalData,
	Backend,
	Operations,
	Users,
	LocalUsers,
	Tags,
	LocalTags,
	Versions,
	Progress: fm.services.Progress,
	Upload: fm.services.Upload,
};
const locales = { en: webix.extend(en, fm.locales.en) };

export { views, locales, services };
