import { views } from "@xbs/filemanager";
import { randomColor, palette } from "../../helpers/common";

export default class TagsPopupView extends views.JetView {
	config() {
		const _ = this.app.getService("locale")._;
		const compact = this.getParam("compact", true);

		const search = {
			margin: 4,
			cols: [
				{
					view: "search",
					localId: "search",
					placeholder: _("Type to search a tag"),
					on: {
						onTimedKeyPress: () => this.FilterTags(),
						onChange: v => !v && this.FilterTags(),
					},
				},
				{
					view: "button",
					value: _("Add"),
					css: "webix_primary",
					width: 90,
					click: () => {
						this.AddTag();
					},
				},
			],
		};

		const size = webix.skin.$active.propertyItemHeight + 1;
		const list = {
			localId: "tags",
			view: "datatable",
			editable: true,
			header: false,
			yCount: compact ? 0 : 4,
			columns: [
				{
					id: "name",
					editor: "text",
					fillspace: true,
					template: obj => this.SearchTemplate(obj.name),
				},
				{
					id: "color",
					editor: "color",
					width: 50,
					template: obj => this.TaglistTemplate(obj),
					suggest: {
						css: "webix_fmanager_palette",
						type: "colorboard",
						padding: 3,
						body: {
							view: "colorboard",
							palette,
							width: 4 * size,
							height: 4 * size,
						},
					},
				},
				{
					width: 50,
					template:
						"<span class='webix_fmanager_remove_tag webix_icon wxi-trash'></span>",
				},
			],
			on: {
				onBeforeEditStop: (values, editor) =>
					this.ProcessTagEditing(values, editor),
				"data->onSyncApply": () => {
					// workaround for refilter of slave after CRUD on master
					this.FilterTags();
				},
			},
			onClick: {
				webix_fmanager_remove_tag: (e, id) => {
					this.RemoveTag(id.row);
				},
			},
		};

		const body = {
			borderless: true,
			padding: 12,
			margin: 0,
			type: "clean",
			rows: [search, list],
		};

		return body;
	}

	init() {
		this.List = this.$$("tags");
		this.Search = this.$$("search");
		this.SetTaglistOverlay();
		this.app
			.getService("localtags")
			.tags()
			.then(data => {
				this.List.parse(data);
				this.Search.focus();
			});
	}

	/**
	 * Processes tag data before updating the tag and then updates it
	 * @param {Object} values - the old and new value from the editor
	 * @param {Object} editor - the editor from the cell
	 * @returns {Boolean} - return false to cancel default Webix logic for editing (for pessimistic data saving)
	 */
	ProcessTagEditing(values, editor) {
		if (values.value !== values.old) {
			const data = this.$$("tags").getItem(editor.row);
			data[editor.column] = values.value;

			this.UpdateTag(editor.row, {
				name: data.name,
				color: data.color,
			});

			return false;
		}
	}

	/**
	 * Searches for tags that match the search string from the input field
	 */
	FilterTags() {
		this._Input = this.Search.getValue()
			.trim()
			.toLowerCase();
		this.List.filter(t => t.name.toLowerCase().indexOf(this._Input) !== -1);
	}

	/**
	 * Adds a new tag to DM database
	 */
	AddTag() {
		const tag = { name: "New", color: randomColor() };
		this.app
			.getService("operations")
			.addTag(tag)
			.then(id => {
				this.Search.setValue("");
				this.List.editCell(id, "name", false, true);
			});
	}

	/**
	 * Updates a tag
	 * @param {number} id - the ID of a tag
	 * @param {Object} data - the tag data object
	 */
	UpdateTag(id, data) {
		this.app
			.getService("operations")
			.updateTag(id, data)
			.then(() => {
				this.List.blockEvent();
				this.List.editStop();
				this.List.unblockEvent();
			});
	}

	/**
	 * Removes a tag
	 * @param {number} id - the ID of a tag
	 */
	RemoveTag(id) {
		this.app.getService("operations").removeTag(id);
	}

	/**
	 * Sets event handlers for the tag list that toggle the overlay
	 */
	SetTaglistOverlay() {
		webix.extend(this.List, webix.OverlayBox);

		this.on(this.List.data, "onAfterFilter", () => this.ToggleOverlay());
		this.on(this.List.data, "onStoreUpdated", () => this.ToggleOverlay());
	}

	/**
	 * Toggles the overlay on the tag list
	 */
	ToggleOverlay() {
		const _ = this.app.getService("locale")._;
		if (!this.List.count()) {
			this.List.showOverlay(_("Click Add to create a new tag"));
		} else {
			this.List.hideOverlay();
		}
	}

	/**
	 * Defines the way tags look in the list
	 * @param {Object} obj - a tag data object
	 * @returns {string} - with HTML
	 */
	TaglistTemplate(obj) {
		return `<span class="webix_fmanager_tag_color" style="background-color: ${obj.color};">&nbsp;</span>`;
	}

	/**
	 * Highlights the part of a tag name that matches the search string from the input
	 * @param {string} name - the name of a tag
	 * @returns {string} - the matching substring of the name
	 */
	SearchTemplate(name) {
		if (this._Input) {
			const rex = new RegExp("(" + this._Input + ")", "gi");
			return name.replace(
				rex,
				"<span class='webix_fmanager_search_mark'>$1</span>"
			);
		}

		return name;
	}
}
