import { views } from "@xbs/filemanager";

export default class PopupView extends views.JetView {
	config() {
		const _ = this.app.getService("locale")._;
		const compact = this.getParam("compact");
		const label = _(this.getParam("label"));

		const header = {
			view: "toolbar",
			padding: { left: 12, right: 4 },
			borderless: true,
			cols: [
				{
					template: () => label,
					height: 36,
					borderless: true,
					css: "webix_fmanager_shared_title",
				},
				{
					view: "icon",
					icon: "wxi-close",
					css: "webix_fmanager_shared_close",
					hotkey: "esc",
					click: () => this.getRoot().hide(),
				},
			],
		};

		const win = {
			view: "window",
			borderless: true,
			css: "webix_fmanager_popup",
			head: header,
			body: { $subview: true },
			on: {
				onHide: () => {
					this.show("_blank", { target: "popup" });
				},
			},
		};

		if (compact) win.fullscreen = true;
		else {
			const small = webix.skin.$name == "mini" || webix.skin.$name == "compact";
			win.width = small ? 400 : 540;
		}

		this.app.getService("jet-win").updateConfig(win);

		return win;
	}
}
