import m0 from "./views/cards";
import m1 from "./views/editor/back";
import m2 from "./views/editor/excel";
import m3 from "./views/editor/history";
import m4 from "./views/editor/index.js";
import m5 from "./views/folders";
import m6 from "./views/list";
import m7 from "./views/menus/contextmenu";
import m8 from "./views/menus/menubody";
import m9 from "./views/menus/popup";
import m10 from "./views/menus/sharepopup";
import m11 from "./views/menus/tagspopup";
import m12 from "./views/panel-double";
import m13 from "./views/panel";
import m14 from "./views/preview/comments";
import m15 from "./views/preview/index.js";
import m16 from "./views/preview/info";
import m17 from "./views/sections/dataview";
import m18 from "./views/top";
import m19 from "./views/topbar";

const views = {};
views["cards"] = m0;
views["editor/back"] = m1;
views["editor/excel"] = m2;
views["editor/history"] = m3;
views["editor"] = m4;
views["folders"] = m5;
views["list"] = m6;
views["menus/contextmenu"] = m7;
views["menus/menubody"] = m8;
views["menus/popup"] = m9;
views["menus/sharepopup"] = m10;
views["menus/tagspopup"] = m11;
views["panel-double"] = m12;
views["panel"] = m13;
views["preview/comments"] = m14;
views["preview"] = m15;
views["preview/info"] = m16;
views["sections/dataview"] = m17;
views["top"] = m18;
views["topbar"] = m19;

export default views;
