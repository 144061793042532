import { views } from "@xbs/filemanager";

export default class FoldersView extends views.folders {
	config() {
		const ui = super.config();

		const navbar = ui.body.rows[1];
		navbar.type.folder = o => {
			const iconPrefix = o.icon ? o.icon.slice(0, 3) : "";
			let icl = iconPrefix === "dmi" ? "webix_docmanager_icon" : "webix_icon";
			return `<div class='${icl} ${o.icon || "wxi-folder"}'></div>`;
		};

		navbar.on.onBeforeDrop = ctx => this.HandleDrop(ctx);

		return ui;
	}
	init() {
		super.init();

		this.DragMark();
	}
	DragMark() {
		webix.extend(
			this.Tree,
			{
				$dragMark: ctx => {
					if (this.Tree._marked && this.Tree._marked != ctx.target) {
						this.Tree.removeCss(this.Tree._marked, "webix_drag_over");
					}

					if (ctx.target) {
						const trashing =
							ctx.target === "../trash" && ctx.stateSource !== "trash";
						const toGroupFolder =
							ctx.target.indexOf("..") === 0 && ctx.target !== "../files";
						if (!toGroupFolder || trashing) {
							this.Tree.addCss(ctx.target, "webix_drag_over");
							this.Tree._marked = ctx.target;
						}
					}
				},
			},
			true
		);
	}
	HandleDrop(ctx) {
		if (!ctx.target) return false;

		if (ctx.target === "../files") ctx.target = "/";

		const fromTrash = ctx.stateSource === "trash";
		const toGroupFolder = ctx.target.indexOf("..") === 0;

		if (!toGroupFolder) {
			if (fromTrash) {
				this.app.callEvent("app:action", ["restore", ctx.source, ctx.target]);
			} else {
				this.app.getService("operations").move(ctx.source, ctx.target);
			}
		} else if (ctx.target === "../trash" && !fromTrash) {
			this.app.callEvent("app:action", ["delete", ctx.items]);
		}
		return false;
	}
	Subscribe() {
		super.Subscribe();

		this.on(this.State.$changes, "source", v => {
			if (v !== "files" || this.State.path === "/") this.Tree.select("../" + v);
			const addButton = this.getRoot().queryView("button");
			if (v !== "files") {
				addButton.disable();
				if (v === "trash") this.State.preview = false;
			} else {
				addButton.enable();
			}
		});
	}
}
