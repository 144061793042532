import hotkey from "jet-hotkey";

const DataView = {
	/**
	 * Moves files and folders to a different directory
	 * @param {Array} files - an array of item IDs
	 * @param {string} target - the target folder ID
	 * @returns {Boolean} always false to cancel default Webix dnd logic (for pessimistic data operations)
	 */
	MoveFiles(files, target) {
		if (this.State.source === "files") {
			const ops = this.app.getService("operations");
			ops.move(ops.extractIds(files), this.GetTargetFolder(target));
		} else if (this.State.source === "trash") {
			this.app.callEvent("app:action", ["delete", files]);
		}

		return false;
	},

	/**
	 * Adds hotkeys for operations with files and folders depending on the group folder
	 */
	AddHotkeys() {
		const ctrlKey = webix.env.isMac ? "COMMAND" : "CTRL";

		// "delete" means "put to trash", "remove" means "remove from the trash"
		const operations = [
			{ key: "DELETE", oper: "delete", trash: "remove" },
			{ key: "BACKSPACE", oper: "delete", trash: "remove" },
			{ key: `${ctrlKey}+C`, oper: "copy" },
			{ key: `${ctrlKey}+X`, oper: "cut", trash: "trashed" },
			{ key: `${ctrlKey}+V`, oper: "paste", trash: "paste" },
			{ key: `${ctrlKey}+R`, oper: "rename" },
			{ key: `${ctrlKey}+O`, oper: "open" },
			{ key: `${ctrlKey}+E`, oper: "edit" },
			{ key: `${ctrlKey}+D`, oper: "download" },
		];

		for (let i = 0; i < operations.length; ++i) {
			this.on(hotkey(this.getRoot()), operations[i].key, (v, e) => {
				const source = this.State.source;
				const action =
					source !== "trash" ? operations[i].oper : operations[i].trash;
				if (action) this.app.callEvent("app:action", [action]);
				webix.html.preventEvent(e);
			});
		}
	},

	/**
	 * Handles the changes of the 'source' reactive property
	 */
	SourceHandlers() {
		this.on(this.State.$changes, "source", (v, o) => {
			if (o || (!o && v !== "files")) {
				if (this.State.source !== "files") {
					this.State.selectedItem = [];
					this.LoadData(this.State.path, "", v);
				} else {
					this.LoadData(this.State.path);
				}
			}
		});
	},

	/**
	 * Denies dragging files and folders from group folders other that 'files' and 'trash'
	 * Otherwise adds source to the context of DND and proceeds
	 * @param {Object} ctx - the context object of Webix DND
	 */
	HandleDrag(ctx) {
		const source = this.State.source;
		if (source !== "files" && source !== "trash") return false;
		else {
			ctx.items = ctx.source.map(f => this.WTable.getItem(f));
			ctx.stateSource = source;
			return this.__proto__.DragMarker.call(this, ctx);
		}
	},

	/**
	 * Handles drag-and-drop logic for trash and other group folders
	 * @param {Object} ctx - the context object of Webix DND
	 * @returns {Boolean, void} - return false to cancel default Webix logic (for pessimistic data operations), nothing or true otherwise
	 */
	HandleDrop(ctx) {
		if (ctx.stateSource === "trash") {
			if (this.State.source === "trash") return false;
			this.app.callEvent("app:action", [
				"restore",
				ctx.source,
				this.GetTargetFolder(ctx.target),
			]);
		} else return this.MoveFiles(ctx.items, ctx.target);
	},

	/**
	 * Loads folders and files inside the opened directory
	 * @param {string} path - the currently opened directory
	 * @param {Boolean} search - pass true to signal that the data are loaded in response to a search query
	 * @param {string} source - the group folder ("files", "trash", "favorite", "recent", "shared")
	 */
	LoadData(path, search, source) {
		if (!source) this.__proto__.LoadData.call(this, path, search);
		else {
			this._Track = false;
			const table = this.WTable;
			if (table.data.url !== path) {
				table.clearAll();
				this.app
					.getService("local")
					.files(path, false, source)
					.then(data => {
						this.RenderData(data);
						this.SelectActive();
					});
			}
			this._Track = true;
		}
	},

	/**
	 * Opens a folder or file(s)
	 * @param {Array} items - selected files and folders
	 */
	Activate(items) {
		if (!items.length) return; // nothing selected - ignore

		if (items.length === 1) {
			const item = items[0];

			if (!(this.State.source === "trash" && item.id !== "/")) {
				if (!items.length) return; // nothing selected - ignore

				if (item.type === "folder") {
					this.ShowSubFolder(item.value === ".." ? item.value : item.id);
				} else {
					if (
						item.type === "code" ||
						(item.$ext === "xls" || item.$ext === "xlsx")
					) {
						this.app.callEvent("app:action", ["edit", items]);
					} else {
						this.app.callEvent("app:action", ["open", items]);
					}
				}
			}
		} else if (this.State.source !== "trash") {
			this.app.callEvent("app:action", ["edit", items]);
		}
	},

	/**
	 * Opens a selected directory
	 * @param {string} id - the ID of the selected folder
	 */
	ShowSubFolder(id) {
		this.State.source = "files";
		this.__proto__.ShowSubFolder.call(this, id);
	},

	DragMark() {
		webix.extend(
			this.WTable,
			{
				$dragMark: ctx => {
					if (this.WTable._marked && this.WTable._marked != ctx.target) {
						this.WTable.removeCss(this.WTable._marked, "webix_drag_over");
					}

					if (ctx.target && this.State.source !== "trash") {
						this.WTable.addCss(ctx.target, "webix_drag_over");
						this.WTable._marked = ctx.target;
					} else if (
						this.State.source == "trash" &&
						ctx.stateSource &&
						ctx.stateSource !== "trash"
					) {
						webix.html.addCss(this.WTable.$view, "webix_drag_over");
					}
				},
				$dragOut: (src, ot, nt) => {
					if (this.State.source == "trash" && ot !== nt) {
						webix.html.removeCss(this.WTable.$view, "webix_drag_over");
					}
				},
			},
			true
		);
	},
};

export default DataView;
