import { views } from "@xbs/filemanager";

export default class TopBarView extends views.topbar {
	config() {
		const bar = super.config();
		const skin = webix.skin.$active;
		const _ = this.app.getService("locale")._;

		const preview = bar.cols.find(c => c.localId === "previewMode");
		preview.icon = "dmi-gesture-double-tap";

		bar.cols.splice(5, 0, {
			localId: "emptyTrash",
			css: "webix_fmanager_empty_trash",
			view: "button",
			type: "icon",
			icon: "dmi-delete-empty",
			tooltip: _("Empty trash"),
			width: skin.toolbarHeight < 37 ? 48 : 60,
			hidden: true,
			click: () => this.app.callEvent("app:action", ["remove", "all"]),
		});

		return bar;
	}
	init() {
		super.init();

		const common = this.getParam("state");
		this.on(common.$changes, "source", (v, o) => {
			if (v === "trash") {
				this.$$("emptyTrash").show();
				this.$$("previewMode").hide();
			} else if (o === "trash") {
				this.$$("emptyTrash").hide();
				if (!this.getParam("compact")) this.$$("previewMode").show();
			}
		});
	}
}
