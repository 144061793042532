export default class Versions {
	/**
	 * @constructor
	 * @param {Object} _app - the Jet app
	 * @param {string} url - the path to the backend server
	 */
	constructor(_app, url) {
		this._url = url;
	}

	/**
	 * Returns the full path for a request
	 * @param {string} path - a partial path
	 */
	url(path) {
		return this._url + path;
	}

	/**
	 * Returns the version history of a file
	 * @param {string} id - the ID of a file (text, Excel)
	 * @returns {Promise} that resolves with an array of objects
	 */
	versions(id) {
		return webix
			.ajax()
			.get(this.url("versions"), { id })
			.then(r => r.json());
	}

	/**
	 * Returns the text of a file version with or without HTML for version difference highlighting
	 * The current version is compared to the previous version on the backend
	 * @param {number} id - the ID of a version
	 * @param {Boolean} diff - if true, text will contain HTML for diff highlighting; if false, there will be just the text from the file
	 * @returns {Promise} that resolves with the text of a file version
	 */
	readText(id, diff) {
		const params = { mode: "text" };
		if (diff) params.diff = true;
		return webix
			.ajax()
			.get(this.url(`versions/${id}`), params)
			.then(r => r.text());
	}

	/**
	 * Restores a file version and saves it as a new version
	 * @param {string} id - the file ID
	 * @param {number} version - the ID of a file version
	 * @returns {Promise} that resolves with the data object of a restored file
	 */
	restore(id, version) {
		return webix
			.ajax()
			.post(this.url("versions"), { id, version })
			.then(r => r.json());
	}

	/**
	 * Returns the full path for a version request
	 * @param {number} id - the ID of a file version
	 * @returns {string}
	 */
	directLink(id) {
		return this.url(`versions/${id}?mode=binary`);
	}
}
