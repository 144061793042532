import { services } from "@xbs/filemanager";

export default class LocalData extends services.LocalData {
	files(path, sync, source) {
		if (!source) return super.files(path, sync);
		else {
			let fs = this.fscache.get(source);
			if (sync) return fs;
			if (fs) return Promise.resolve(fs);

			fs = new webix.DataCollection({
				scheme: {
					$change: this.prepareData,
					$serialize: this.serializeData,
				},
			});
			this.fscache.set(source, fs);
			return this.reload(fs, path, source);
		}
	}
	refresh(path, source) {
		const fs = this.fscache.get(source || path);
		if (fs) return this.reload(fs, path, source);
	}
	reload(fs, path, source) {
		if (!source) return super.reload(fs, path);
		else {
			return this.app
				.getService("backend")
				.files(path, source)
				.then(data => {
					fs.clearAll();
					fs.parse(this.backToFiles().concat(data));
					return fs;
				});
		}
	}
	backToFiles() {
		return [
			{
				type: "folder",
				id: "/",
				value: "..",
				$row: (_obj, common) => {
					return (
						common.backIcon() +
						`<span class='webix_fmanager_back'>${common.backToFiles()}</span>`
					);
				},
			},
		];
	}

	defaultTree() {
		return [
			{ value: "My Files", id: "../files", open: true },
			{
				value: "Favorite",
				$css: { "margin-top": "20px" },
				id: "../favorite",
				icon: "dmi-star",
			},
			{ value: "Recent", id: "../recent", icon: "dmi-history" },
			{ value: "Shared", id: "../shared", icon: "dmi-share-all" },
			{
				value: "Trash",
				$css: { "margin-top": "20px" },
				id: "../trash",
				icon: "wxi-trash",
			},
		];
	}

	addFile(id, item) {
		if (!this.hierarchy.exists(id)) id = "/";
		super.addFile(id, item);
	}
}
