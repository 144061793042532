import { services } from "@xbs/filemanager";

export default class Backend extends services.Backend {
	files(id, source) {
		if (!source) return super.files(id);
		else {
			return this._files(this.url("files"), { id, source });
		}
	}
	removePermanent(id) {
		return webix
			.ajax()
			.del(this.url(`delete?id=${encodeURIComponent(id)}`))
			.then(r => r.json());
	}
	restore(id, target) {
		return webix
			.ajax()
			.put(this.url("delete"), {
				id,
				target,
			})
			.then(r => r.json());
	}
	favour(id) {
		return webix
			.ajax()
			.post(this.url("favorite"), {
				id,
			})
			.then(r => r.json());
	}
	unfavour(id) {
		return webix
			.ajax()
			.del(this.url(`favorite?id=${encodeURIComponent(id)}`))
			.then(r => r.json());
	}
	writeBinary(id, filename, upload) {
		const fd = new FormData();
		fd.append("upload", upload, filename);

		return webix
			.ajax()
			.post(this.url(`direct?id=${encodeURIComponent(id)}`), fd)
			.then(r => r.json());
	}
}
