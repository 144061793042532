import { views } from "@xbs/filemanager";

export default class PreviewView extends views.preview {
	config() {
		const _ = this.app.getService("locale")._;
		const ui = super.config();

		const toolbar = webix.copy(ui.rows[0]);

		return {
			rows: [
				toolbar,
				{
					localId: "tabview",
					view: "tabview",
					css: "webix_fmanager_preview_tabs",
					cells: [
						{ header: _("Preview"), body: ui.rows[1] },
						{
							header: _("Comments"),
							body: { $subview: true, name: "comments" },
						},
					],
				},
			],
		};
	}

	ShowInfo(v) {
		super.ShowInfo(v);

		const tabbar = this.$$("tabview").getTabbar();
		if (v.type === "empty") {
			tabbar.setValue(tabbar.config.options[0].id);
			tabbar.hide();
		} else {
			tabbar.show();
			this.show("preview.comments", {
				target: "comments",
				params: { file: v },
			});
		}
	}
}
