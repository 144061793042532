import { views } from "@xbs/filemanager";

export default class BackFromHistoryView extends views.JetView {
	config() {
		const _ = this.app.getService("locale")._;
		return {
			view: "icon",
			css: "webix_fmanager_history_back",
			icon: "dmi-arrow-left",
			tooltip: _("Back to editor"),
			click: () => {
				this.app.callEvent("history:close", []);
			},
		};
	}
}
