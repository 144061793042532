import { views } from "@xbs/filemanager";
import DataviewMixin from "helpers/dataview";
import { folderIcon } from "helpers/icons";

export default class GridView extends views.list {
	config() {
		DataviewMixin(this);

		const grid = super.config();

		const _ = this.app.getService("locale")._;
		grid.type.backToFiles = () =>
			_("back to ") + this.app.getService("backend").getRootName();

		grid.on.onBeforeDrag = ctx => this.HandleDrag(ctx);
		grid.on.onBeforeDrop = ctx => this.HandleDrop(ctx);

		return grid;
	}
	NameTemplate(obj) {
		const star =
			obj.star && this.State.source !== "trash"
				? "<div class='webix_fmanager_star'><span class='webix_docmanager_icon dmi-star'></span></div>"
				: "";
		return (
			star +
			(obj.type === "folder"
				? folderIcon + obj.value
				: `${this.Icon(obj)}<span class="file-name">${obj.value}</span>`)
		);
	}

	init() {
		super.init();

		this.SourceHandlers();
		this.DragMark();
	}
}
