export default class LocalUsers {
	constructor(app) {
		this.app = app;
		this.userlist = new webix.DataCollection({
			scheme: {
				$change: o => {
					o.value = o.name;
				},
			},
		});
		this.users_ready = null;
	}
	users(force) {
		const users = this.userlist;

		if (force || !this.users_ready) {
			this.users_ready = this.app
				.getService("users")
				.users()
				.then(data => {
					users.clearAll();
					users.parse(data);

					return users;
				});
		}

		return this.users_ready;
	}
}
