import EditorView from "./index.js";
import hotkey from "jet-hotkey";

export default class ExcelView extends EditorView {
	config() {
		const ui = super.config();

		// replace tabbar and history label with 'classic' controls
		if (!webix.env.mobile) {
			const barControls = ui.rows[0].cols;
			barControls.splice(0, 2, ...this.GetMobileControls());
			barControls.splice(barControls.length - 1, 1, this.GetCloseAll(true));
		}

		ui.rows[1].cols[0] = {
			view: "excelviewer",
			editable: true,
			editaction: "dblclick",
			css: "webix_data_border",
			areaselect: true,
			header: false,
			localId: "editor",
		};

		if (this.Compact) {
			ui.rows[1].rows = ui.rows[1].cols;
			delete ui.rows[1].cols;
		}

		return ui;
	}

	init() {
		// logic from dm-editor excluding logic from fm-editor
		this.HandleVersioning();

		this._changed = {};
		this.File = this.Files[0]; // always single-file

		this.$$("name").setValue(
			`<div class="filename">${this.ClipName(
				this.File.id
			)}</div><span class="extension">.${this.File.$ext}</span>`
		);

		webix.extend(this.Editor, webix.ProgressBar);
		this.Editor.showProgress({ type: "top" });

		this.LoadFile(this.File.id, true);

		this.on(this.Editor, "onAfterEditStop", v => {
			if (v.value != v.old) this.ChangeTextState(true, this.File.id);
		});

		this.on(hotkey(this.Editor), "any", () => {
			const sel = this.Editor.getSelectedId();
			if (sel) this.Editor.edit(sel);
		});

		this.on(this.State.$changes, "version", v => {
			this.Diffs = null;
			this.ParseVersion(v, this.State.diff);
		});

		this.on(this.State.$changes, "diff", v => {
			if (this.Diffs) this.ResetDiffStyle(this.State.version, v);
			else {
				this.ParseVersion(this.State.version, v);
			}
		});

		// editor hotkeys for saving (from FM)
		this.on(hotkey(), `${webix.env.isMac ? "COMMAND" : "CTRL"} + S`, (v, e) => {
			this.Save();
			webix.html.preventEvent(e);
		});
		this.on(hotkey(), "ESC", () => this.ConfirmAll());
	}

	/**
	 * Loads a file or a file version into the editor
	 * @param {string, number} id - the ID of a file or a version
	 * @param {Boolean} editor - if true, latest file version for editor is loaded; if false, a specific version is loaded for history
	 */
	LoadFile(id, editor) {
		const path = this.app
			.getService(editor ? "backend" : "versions")
			.directLink(id);
		this.Editor.load(`binary->${path}`, "excel").then(() => {
			this.Editor.hideProgress();
			if (editor) this.SetVersionsButton(this.File);
		});
	}

	/**
	 * Adds or clears highlighting for cells different from the previous version
	 * @param {number} v - the ID of a version
	 * @param {Boolean} diff - if true, highlighting will be added; if false, cleared
	 */
	ResetDiffStyle(v, diff) {
		if (v) {
			const id = this.getSubView("r-side").GetPrevVersion(v);
			if (id) {
				this.StyleDiffCells(diff);
			}
			this.ResetTotalDiff(id ? false : diff);
		}
	}

	/**
	 * Loads and displays a file version
	 * @param {number} v - the ID of a version
	 * @param {Boolean} diff - if true, this version will be compared to the previous; false otherwise
	 */
	ParseVersion(v, diff) {
		if (v) {
			this.Editor.showProgress({ type: "top" });
			this.Editor.clearAll();

			const id = this.getSubView("r-side").GetPrevVersion(v);

			this.ResetTotalDiff(id ? false : diff);

			if (diff && id) {
				webix.promise
					.all([this.ParseExcel(id), this.ParseExcel(v)])
					.then(res => {
						const older = res[0];
						const target = res[1];
						this.Diffs = this.CollectDiffs(older.data, target.data);
						this.Editor.parse(target);
						this.StyleDiffCells(true);
					});
			} else {
				this.LoadFile(v);
			}
		}
	}

	/**
	 * Highlights all cells as different or clears highlighting
	 * @param {Boolean} mode - if true, cells will be highlighted; if false, highlighting will be removed
	 */
	ResetTotalDiff(mode) {
		const method = mode ? "addCss" : "removeCss";
		webix.html[method](this.Editor.$view, "webix_fmanager_diff_all");
	}

	/**
	 * Loads an Excel file (version) and parses it
	 * @param {string} id - the ID of a version
	 * @returns {Promise} that resolves with the loaded file as a data object (created by Excel dataDriver)
	 */
	ParseExcel(id) {
		const url = this.app.getService("versions").directLink(id);
		return webix
			.ajax()
			.response("arraybuffer")
			.get(url)
			.then(data =>
				webix.DataDriver.excel.toObject({
					data,
					options: { ext: this.File.$ext },
				})
			);
	}

	/**
	 * Collects cells that are different between two file versions
	 * @param {Array} old - 2-d array of cells of the older version
	 * @param {Array} target - 2-d array of cells of the current version
	 * @returns {Array} - 2-d array of cells that are different
	 */
	CollectDiffs(old, target) {
		const diffs = [];
		for (let row = 0; row < target.length; ++row) {
			for (let col = 0; col < target[row].length; ++col) {
				if (target[row][col] != old[row][col]) {
					diffs.push([row, col]);
				}
			}
		}
		return diffs;
	}

	/**
	 * Marks cells as different from same cells from previous file version
	 * @param {Boolean} mode - if true, a cell will be marked as different; if false, a cell will be unmarked
	 */
	StyleDiffCells(mode) {
		this.Editor.showProgress({ type: "top" });

		const method = mode ? "addCellCss" : "removeCellCss";
		this.Diffs.forEach(d => {
			const colId = this.Editor.columnId(d[1]);
			this.Editor[method](
				this.Editor.getIdByIndex(d[0]),
				colId,
				"webix_docmanager_diff_excel",
				true
			);
		});

		this.Editor.render();
		this.Editor.hideProgress();
	}

	/* Redefined DM text editor and FM editor methods */

	/**
	 * Saves the file in the currently active tab (multi mode) or the only file in single mode
	 * @param {string} id - the file ID
	 * @returns {Promise}
	 */
	Save(id) {
		this.Editor.editStop();

		if (!id) id = this.File.id;
		if (this._changed[id]) {
			return webix
				.toExcel(this.Editor, {
					download: false,
					styles: true,
					spans: true,
					heights: true,
					header: false,
				})
				.then(data => {
					return this.app
						.getService("operations")
						.writeBinary(id, this.File.value, data)
						.then(() => {
							this.ChangeTextState(false, id);
						});
				});
		}
		return webix.promise.resolve();
	}

	/**
	 * Hides the history of a file and goes back to the editor
	 */
	HideHistory() {
		this.refresh();
	}

	/**
	 * Switches the UI of the editor to history view or to editor view
	 * @param {string} batch - "editor" or "history"
	 */
	SetBatch(batch) {
		this.getRoot()
			.queryView("toolbar")
			.showBatch(batch);
		if (batch === "history") {
			this.Editor.define("editable", false);
		}
	}

	/**
	 * Gets the ID of the currently active file
	 * @returns {string} - the file ID
	 */
	GetActiveFile() {
		return this.File.id;
	}

	/**
	 * Turns on/off the 'light bulbs' on file tabs and updates the label on the versions button
	 * @param {Boolean} state - true if changed, false if unchanged
	 */
	ChangeTextState(state) {
		const file = this.File.id;
		if (state === !!this._changed[file]) return;

		this._changed[file] = state;
		this.ChangeButtonState(state);

		if (!state) {
			this.SetVersionsButton(this.File);
		}
	}

	/**
	 * Restores a version from editing history and saves it as the latest version
	 */
	RestoreVersion() {
		const id = this.File.id;
		webix
			.confirm({
				text: this._("Are you sure ?"),
				container: this.app.getRoot().$view,
			})
			.then(() => {
				this.app
					.getService("versions")
					.restore(id, this.State.version * 1)
					.then(() => this.HideHistory());
			});
	}
}
