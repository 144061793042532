import { views } from "@xbs/filemanager";

export default class ContextMenuView extends views["menus/contextmenu"] {
	config() {
		const ui = super.config();

		ui.on.onBeforeShow = e => {
			const files = this._Locate(e);
			const source = this.app.getState().source;
			if (files && (files[0].type !== "empty" || source === "files"))
				this.getSubView("options").FilterOptions(files);
			else return false;
		};

		return ui;
	}
}
