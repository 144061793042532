export const folderCardIcon =
	"<span class='webix_icon wxi-folder webix_fmanager_folder_icon'></span>";
export const menuIcon =
	"<span class='webix_icon wxi-dots webix_fmanager_menu_icon'></span>";
export const suggestIcon = "<span class='webix_icon wxi-menu-down'></span>";
export const upIcon = "<span class='webix_icon wxi-menu-up'></span>";
export const downIcon = "<span class='webix_icon wxi-menu-down'></span>";
export const folderIcon = "<span class='webix_icon wxi-folder'></span>";
export const deleteIcon =
	"<span class='webix_fmanager_remove_user webix_icon wxi-trash'></span>";
