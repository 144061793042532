import { views } from "@xbs/filemanager";
import { createState } from "jet-restate";
import BackFromHistory from "./back";

export default class EditorView extends views["editor"] {
	config() {
		const ui = super.config();

		this.Compact = this.getParam("compact");

		// add batches for existing bar controls
		const bar = ui.rows[0];
		bar.visibleBatch = "editor";
		bar.cols[0].batch = "editor"; // Save button or tabbar to batch "editor"
		if (webix.env.mobile || this.Files.length > 1) {
			// Close button
			bar.cols[bar.cols.length - 1].batch = "editor";
		}

		// add new bar controls
		if (!webix.env.mobile) {
			// the label with the file name is on the toolbar only on mobile devices in FM
			bar.cols.splice(bar.cols.length - 1, 0, {
				view: "label",
				label: " ",
				localId: "name",
				batch: "history",
				css: "webix_fmanager_editor_name",
			});
		}

		const labelInd = bar.cols.findIndex(c => c.localId === "name");
		if (labelInd > -1) bar.cols.splice(labelInd + 1, 0, { batch: "history" });

		if (this.Compact) {
			bar.cols.unshift({
				view: "proxy", // for batches
				batch: "history",
				css: "webix_fmanager_back_proxy",
				borderless: true,
				body: BackFromHistory,
			});
		}

		bar.cols.splice(
			bar.cols.length - 1,
			0,
			this.GetVersionsButton(),
			this.GetRestoreButton()
		);

		// add the history panel
		const editor = ui.rows[1];
		const main = {
			type: "wide",
			cols: [
				editor,
				{
					view: "proxy",
					borderless: true,
					hidden: true,
					localId: "r-side",
					body: {
						$subview: true,
						name: "r-side",
					},
				},
			],
		};
		ui.rows[1] = main;

		return ui;
	}

	/**
	 * Returns the UI configuration object of the 'Restore version' button
	 */
	GetRestoreButton() {
		const ui = {
			view: "button",
			localId: "restoreBtn",
			hidden: true,
			css: "webix_primary",
			align: "center",
			click: () => this.RestoreVersion(),
		};
		if (this.Compact) {
			ui.value = this._("Restore");
			ui.autowidth = true;
		} else {
			ui.value = this._("Restore this version");
			ui.width = 296;
			ui.inputWidth = 200;
		}
		return ui;
	}

	/**
	 * Returns the UI configuration object of the 'Show version history' button
	 */
	GetVersionsButton() {
		return {
			localId: "versionsBtn",
			view: "button",
			type: "icon",
			icon: "wxi-clock",
			label: this.Compact ? "History" : "",
			css: "webix_transparent webix_fmanager_versions",
			autowidth: true,
			tooltip: this._("Show version history"),
			batch: "editor",
			hidden: true,
			click: () => this.ShowHistory(),
		};
	}

	init() {
		super.init();
		this.HandleVersioning();
	}

	/**
	 * Runs DM-specific logic in addition to the inherited logic from FM
	 */
	HandleVersioning() {
		this.Editor = this.$$("editor");

		this.State = createState({
			version: 0,
			current: true,
			date: null,
			diff: true,
		});

		this.on(this.State.$changes, "current", v => {
			if (v) this.$$("restoreBtn").hide();
			else this.$$("restoreBtn").show();
		});

		this.on(this.State.$changes, "date", v => {
			if (v) {
				this.SetMainLabel(v);
			}
		});

		this.on(this.app, "history:close", () => {
			this.HideHistory();
		});
	}

	/**
	 * Changes the contents of the main toolbar label
	 * (seen in 'history' view on desktop devices and at all times on touch devices)
	 * @param {Date} versionDate - (optional) the date of the version
	 * @param {string} filename - (optional) the file name
	 */
	SetMainLabel(versionDate, filename) {
		const barLabel = this.$$("name");
		const old = barLabel.getValue().split(", ");
		if (versionDate) {
			const dateLabel = `<span class="date">${
				this.DateFormat(versionDate).date
			}</span>`;
			old[1] = dateLabel;
			barLabel.setValue(old[0] + ", " + old[1]);
		} else {
			barLabel.setValue(filename || old[0]);
		}
	}

	/**
	 * Formats a version date depending on the current date
	 * @param {Date} date - a date of a version
	 * @returns {Object} with a preposition (string) and a formatted date label (string)
	 */
	DateFormat(date) {
		let format = "";
		let preposition = "on";
		const today = webix.Date.datePart(new Date());
		const edit = webix.Date.datePart(date, true);

		if (webix.Date.equal(today, edit)) {
			format = "%H:%i:%s";
			preposition = "at";
		} else if (edit.getYear() === today.getYear()) {
			format = "%F %j";
		} else {
			format = "%F %j, %Y";
		}
		return { preposition, date: webix.Date.dateToStr(format)(date) };
	}

	/**
	 * Shows the button that leads to the version history;
	 * also updates a label on it in full mode only
	 * @param {Object} file - a file data object
	 */
	SetVersionsButton(file) {
		const btn = this.$$("versionsBtn");
		if (!this.State.version) {
			if (!btn.isVisible()) btn.show();

			if (!this.Compact) {
				const format = this.DateFormat(file.date);
				btn.config.label = `${this._(`Last edited ${format.preposition}`)} ${
					format.date
				}`;
				btn.resize();
				btn.refresh();
			}
		}
	}

	/**
	 * Shows the editing history of a file
	 */
	ShowHistory() {
		const id = this.GetActiveFile();
		this.Save(id).then(() => {
			const file = this.GetFileData(id);
			this.show("editor.history", {
				target: "r-side",
				params: { file, state: this.State, compact: this.getParam("compact") },
			});
			this.$$("r-side").show();

			this.SetBatch("history");
		});
	}

	/**
	 * Returns the data object of a file
	 * @param {string} id - the file ID
	 * @returns {Object}
	 */
	GetFileData(id) {
		return this.Files.find(f => f.id === id);
	}

	/**
	 * Hides the history of a file and goes back to the editor
	 */
	HideHistory() {
		this.show("_blank", { target: "r-side" });
		this.$$("r-side").hide();

		this.SetBatch("editor");
	}

	/**
	 * Switches the UI of the editor to history view or to editor view
	 * @param {string} batch - "editor" or "history"
	 */
	SetBatch(batch) {
		this.getRoot()
			.queryView("toolbar")
			.showBatch(batch);
		if (batch === "history") {
			this.Editor.hide();
		} else {
			this.Editor.show();
			this.$$("restoreBtn").hide();
			if (webix.env.mobile) {
				this.SetMainLabel();
			}
		}
	}

	/**
	 * Restores a version from editing history and saves it as the latest version
	 */
	RestoreVersion() {
		const id = this.GetActiveFile();
		webix
			.confirm({
				text: this._("Are you sure ?"),
				container: this.app.getRoot().$view,
			})
			.then(() => {
				this.app
					.getService("versions")
					.restore(id, this.State.version * 1)
					.then(data => {
						const old = this.Files.findIndex(f => f.id === id);
						this.Files[old].date = new Date(data.date * 1000);

						this.app
							.getService("operations")
							.read(data.id)
							.then(text => {
								this.HideHistory();
								this.AddDoc(data, text);
								this.OpenDoc(data.id);
							});
					});
			});
	}

	/* Redefined FM methods */

	/**
	 * Turns on/off the 'light bulbs' on file tabs and updates the label on the versions button
	 * @param {Boolean} state - true if changed, false if unchanged
	 * @param {string} file - (optional) a file ID; if not passed, all files are marked as unchanged/saved
	 */
	ChangeTextState(state, file) {
		super.ChangeTextState(state, file);
		if (!state) {
			if (!file) file = this.GetActiveFile();
			this.SetVersionsButton(this.GetFileData(file));
		}
	}

	/**
	 * Opens a file in the editor, the previous file stays in the buffer (in multi mode)
	 * @param {string} name - the file ID (the full path to the file, here serves as the key)
	 */
	OpenDoc(name) {
		this.$$("editor")
			.getEditor(true)
			.then(editor => {
				editor.swapDoc(this._buffers[name]);
				editor.focus();

				// DM-specific: updating history-versions button and setting the main bar label
				const file = this.GetFileData(name);
				this.SetVersionsButton(file);
				this.SetMainLabel(null, this.GetFileLabel(file));
			});
	}
}
